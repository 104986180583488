export const replyModalNewMessage = {
  name: 'Aaron Peterson',
  text: 'Hello, \n\nSorry to hear that.\nHave you tried clearing your browser\'s cache and cookies?\nIf that doesn\'t work, please let us know, and we will investigate further.\n\nRegards,\nAaron Peterson',
  avatar: 'https://randomuser.me/api/portraits/men/34.jpg',
}

export const replyModalMessages = [
  {
    name: 'Markus Ferris',
    avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
    text: 'Thank you. \nI have received the password reset link, but I\'m still having trouble logging in.\nWhat should I do?\n\nMarkus',
    date: '29 Aug 2023, 12:12 am',
  },
  {
    name: 'Aaron Peterson',
    avatar: 'https://randomuser.me/api/portraits/men/34.jpg',
    text: 'Hi,\n\nAbsolutely!We\'ve just sent a password reset link to the email address associated with your account.\nPlease check your inbox and follow the instructions to reset your password.\nIf you don\'t receive the email, please check your spam or junk folder. Let us know if you have any further questions or issues, and we\'ll be happy to help.\n\nRegards,\nAaron Peterson',
    date: '29 Aug 2023, 8:24 am',
  },
  {
    name: 'Markus Ferris',
    avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
    text: 'Hello,\n\nI\'m having trouble logging into my account.\nCan you help me reset my password?\n\nRegards,\nMarkus',
    date: '28 Aug 2023, 4:40 pm',
  },
]
