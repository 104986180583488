
import { defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import type { PropType } from 'vue'
import type { AvatarColorVariant } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmAvatar,
  },
  props: {
    name: {
      type: String,
    },
    text: {
      type: String,
    },
    avatar: {
      type: String,
    },
    avatarColor: {
      type: String as PropType<AvatarColorVariant>,
    },
    date: {
      type: String,
    },
  },
})
