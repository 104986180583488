
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import EmailPreview from '@/components/shared/templates/EmailPreview.vue'
import EmailPreviewMessage from '@/components/shared/templates/emailPreview/EmailPreviewMessage.vue'
import EmailPreviewMessages from '@/components/shared/templates/emailPreview/EmailPreviewMessages.vue'
import { replyModalMessages, replyModalNewMessage } from '@/definitions/tickets/settings/data'

export default defineComponent({
  components: {
    EmailPreviewMessages,
    EmailPreviewMessage,
    TmModal,
    EmailPreview,
  },
  setup() {
    return {
      replyModalNewMessage,
      replyModalMessages,
    }
  },
})
