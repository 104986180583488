
import { defineComponent } from 'vue'
import EmailPreviewMessage from '@/components/shared/templates/emailPreview/EmailPreviewMessage.vue'

export default defineComponent({
  components: {
    EmailPreviewMessage,
  },
  props: {
    messages: {
      type: Array,
    },
  },
})
