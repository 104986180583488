import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-531833fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "email-preview-message" }
const _hoisted_2 = { class: "mb-2 d-flex" }
const _hoisted_3 = { class: "subhead-semi-bold-16 emphasize--text lh-28" }
const _hoisted_4 = {
  key: 0,
  class: "lh-30 ml-1 distinct--text"
}
const _hoisted_5 = { class: "email-preview-message__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_avatar, {
      url: _ctx.avatar,
      color: _ctx.avatarColor,
      class: "mr-2"
    }, null, 8, ["url", "color"]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1),
        (_ctx.date)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.date), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}